<template>
	<v-layout fill-height>
		<DocumentPreview
			v-if="!loading"
			v-model="selectedDocument"
			:loading="loading"
			:version="version"
			:items="items"
			:read-only="readOnly"
			:single-file-mode="newTab"
			:closable="!newTab"
			@close="closePreview()"
		/>
	</v-layout>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import DocumentVersionsService from '@/services/Documents/DocumentVersionsService'
import DocumentEditionService from '@/services/Documents/DocumentEditionService'
import { mapMutations, mapState } from "vuex";
import { isViewable } from "@/helpers/files";

export default {
	name: 'DocumentPreviewFrame',
	components: {
		DocumentPreview: () => ({
			component: import('@/components/Documents/Preview/DocumentPreview')
		})
	},
	mixins: [DocumentsManagerModuleGuard],
	props: {
		document: {
			type: Object,
			required: false,
			default: null
		},
		versionId: {
			type: [String, Number],
			required: false,
			default: null
		},
		documentHash: {
			type: String,
			required: false,
			default: null
		},
		folderHash: {
			type: String,
			required: false,
			default: null
		},
		folderDocuments: {
			type: Array,
			required: false,
			default: () => []
		},
		readOnly: {
			type: Boolean,
			required: false,
			default: () => false
		},
		newTab: {
			type: Boolean,
			required: false,
			default: () => false
		},
		fromRouteFullpath: {
			type: String,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			loading: false,
			fetchedFolder: null,
			version: null,
			getDocumentInProgress: false,
		}
	},
	computed: {
		...mapState({
			selectedDocument: state => state.documents.preview.current,
		}),
		documentVersionId: function () {
			let result = null
			if (this.versionId && typeof this.versionId === 'string') {
				result = parseInt(this.versionId)
			} else if (this.versionId) {
				result = this.versionId
			}
			return result
		},
		latestVersionId: function () {
			return Number(this.selectedDocument?.latestVersion?.id) || null
		},
		items: function () {
			let result = []
			if (this.fetchedFolder?.documents) {
				result = this.fetchedFolder.documents.filter(folderDocument => this.isViewable(folderDocument) || this.isEditable(folderDocument))
			}
			return result
		}
	},
	watch: {
		selectedDocument: {
			handler: function (newValue, oldValue) {
				if (!this.getDocumentInProgress && (!oldValue || newValue != oldValue)) {
					this.updateURL()
				}
			}
		},
		documentHash: {
			handler: function () {
				this.getDocument()
			}
		}
	},
	mounted: function () {
		return this.getDocument()
	},
	methods: {
		...mapMutations({
			setCurrentDocument: 'documents/preview/setCurrent',
		}),
		updateURL: function () {
			if (this.documentHash != this.selectedDocument?.hash) {
				const params = {
					documentHash: this.selectedDocument?.hash,
					folderHash: this.folderHash,
					accounting_firm_id: this.accountingFirmId,
					vendor_id: this.vendorId
				}
				if (this.fromRouteFullpath) {
					params.fromRouteFullpath = this.fromRouteFullpath
				}
				this.appService.goTo({
					name: 'documents-preview-folder',
					params: params
				})
			}
		},
		getDocument: async function () {
			this.getDocumentInProgress = true;
			this.loading = true;

			const parameters = {
				filters: {
					with_content: true
				},
				fields: ['documents', 'documents.is_validated']
			};

			if (this.folderHash === 'trash') {
				parameters.filters.with_trashed = true;
			}

			let result;
			if (this.document?.hash === this.documentHash) {
				result = this.document;
			} else if (this.items?.some(doc => doc.hash === this.documentHash)) {
				result = this.items.find(doc => doc.hash === this.documentHash);
			} else {
				result = await this.service.getFolderDocument(this.vendorId, this.folderHash, this.documentHash);
			}

			this.setCurrentDocument(result);
			await this.loadVersion();

			if (this.fetchedFolder?.hash !== this.folderHash) {
				const isFiltering = this.$store.state.documents.filter.search.filename && this.$store.state.documents.filter.search.filename.length > 0;
				this.fetchedFolder = await this.service.getFolder(this.vendorId, this.folderHash, parameters, isFiltering, true);
			}

			this.loading = false;
			this.getDocumentInProgress = false;
		},
		closePreview: function () {
			if (this.fromRouteFullpath) {
				this.appService.goTo({
					path: this.fromRouteFullpath
				})
			} else {
				this.appService.goTo({
					name: 'home',
					params: { accounting_firm_id: this.accountingFirmId }
				})
			}
		},
		isViewable: function (currentDocument) {
			return currentDocument && isViewable(currentDocument.type)
		},
		isEditable: function (currentDocument) {
			return this.appService.hasModule('document-edition') && DocumentEditionService.isDocumentOnlineEditable(currentDocument)
		},
		resetDocument: function () {
			this.setCurrentDocument(null)
		},
		loadVersion: async function () {
			if (!this.selectedDocument || !this.selectedDocument.id) {
				this.version = null
			}

			try {
				const versions = await DocumentVersionsService.getDocumentVersions(this.vendorId, this.selectedDocument)
				if(this.documentVersionId === this.latestVersionId) {
					this.version = this.selectedDocument.latestVersion
					return;
				}

				this.version = versions.find(version => version.id === this.documentVersionId)
			} catch (error) {
				this.version = null
			}
		}
	}
}
</script>
